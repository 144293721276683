import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const menuItems = [
  { title: "Home", href: "#home" },
  { title: "Services", href: "#services" },
  { title: "Témoignages", href: "#temoignages" },
  { title: "Réalisations", href: "#realisations" },
  { title: "Blogs", href: "#blogs" },
  { title: "Team", href: "#team" },
  { title: "Contact", href: "#contact" }
];

const menuVariants = {
  closed: {
    opacity: 0,
    transform: "translateY(-100%)",
    transition: {
      duration: 0.5,
      when: "afterChildren",
      staggerChildren: 0.1,
    }
  },
  open: {
    opacity: 1,
    transform: "translateY(0%)",
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1,
    }
  }
};

const itemVariants = {
  closed: {
    opacity: 0,
    y: 50,
  },
  open: {
    opacity: 1,
    y: 0,
  }
};

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  return (
    <>
      <div className="w-full fixed top-0 z-[100] h-[12%] flex items-center bg-white">
        <div className="flex w-[91%] justify-between items-center mx-auto">
          <img className="h-full max-h-[60px]" src="./landing/logo.svg" alt="" />

          <motion.div
            className="h-auto cursor-pointer flex flex-col items-center gap-y-[7px] p-2"
            onClick={() => setIsOpen(!isOpen)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <motion.div
              className="h-[1px] bg-[#343434] rounded-full"
              initial={{ width: "50px", opacity: 0, y: 10 }}
              animate={{
                width: "50px",
                opacity: isOpen ? 0 : isHovered ? 1 : 0,
                y: isOpen ? 25 : isHovered ? 0 : 10,
              }}
              transition={{
                duration: 0.3,
                opacity: { duration: isOpen ? 0.1 : 0.3 },
                y: { duration: 0.3 },
              }}
            />

            <motion.div
              className="h-[1px] bg-[#343434] rounded-full"
              initial={{ width: "50px" }}
              animate={{
                width: "50px",
                rotate: isOpen ? 40 : 0,
              }}
              transition={{ duration: 0.3 }}
            />

            <motion.div
              className="h-[1px] bg-[#343434] rounded-full"
              initial={{ width: "50px" }}
              animate={{
                width: "50px",
                rotate: isOpen ? -40 : 0,
                y: isOpen ? -9 : 0,
              }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 bg-white z-[99] flex items-center justify-center"
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <motion.nav className="text-center">
              {menuItems.map((item, index) => (
                <motion.div
                  key={item.title}
                  variants={itemVariants}
                  className="relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(-1)}
                >
                  <a
                    href={item.href}
                    className="block py-4 px-8 text-4xl font-light text-gray-800 hover:text-gray-600 transition-colors relative"
                    onClick={() => setIsOpen(false)}
                  >
                    {hoveredIndex === index && (
                      <motion.div
                        layoutId="menuBackground"
                        className="absolute inset-0 bg-gray-100 rounded-lg -z-10"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      />
                    )}
                    {item.title}
                  </a>
                </motion.div>
              ))}
            </motion.nav>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Header;