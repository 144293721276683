import { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import { Pause, Play } from "lucide-react";
const services: Service[] = [
  {
    id: 1,
    number: "1",
    title: "Digital Strategy",
    description:
      "Customized strategies to enhance your online presence and achieve your business goals in the digital space.",
    videoSrc: "/path-to-video-1.mp4",
  },
  {
    id: 2,
    number: "2",
    title: "Social Media Management",
    description:
      "Effective management of social platforms to increase engagement, build brand loyalty, and connect with your audience.",
    videoSrc: "/path-to-video-2.mp4",
  },
  {
    id: 3,
    number: "3",
    title: "SEO/SEA",
    description:
      "Optimize your website to rank higher on search engines and drive targeted traffic through organic and paid search strategies.",
    videoSrc: "/path-to-video-3.mp4",
  },
  {
    id: 4,
    number: "4",
    title: "Web Design & Development",
    description:
      "Professional web design and development solutions to create a visually appealing and user-friendly online experience.",
    videoSrc: "/path-to-video-4.mp4",
  },
  {
    id: 5,
    number: "5",
    title: "Content Creation",
    description:
      "Engaging and impactful content creation to communicate your brand's message effectively across multiple platforms.",
    videoSrc: "/path-to-video-5.mp4",
  },
  {
    id: 6,
    number: "6",
    title: "Audiovisual Production",
    description:
      "Professional video and audio production services that bring your brand story to life through compelling visual narratives.",
    videoSrc: "/path-to-video-6.mp4",
  },
];
interface Service {
  id: number;
  number: string;
  title: string;
  description: string;
  videoSrc: string;
}
const ServicesSection = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  // Video container animation variants
  const videoContainerVariants = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: .8, 
        ease: "easeOut",
      },
    },
  };

  // Play button animation variants
  const playButtonVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.8,
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };

  // Content animation variants
  const contentVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: (index: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: 1 + index * 0.1,
        duration: 0.5,
        ease: "easeOut",
        opacity: {
          delay: 1 + index * 0.1,
          duration: 1.2,
          ease: [0.645, 0.045, 0.355, 1], // Custom cubic bezier
        },
      },
    }),
  };

  const handleVideoPlayPause = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };
  const isVisible = useInView(sectionRef, { once: true, amount: 0.3 });
  return (
    <div className="h-auto py-[100px]" ref={sectionRef}>
      <motion.div
        className="w-[91%] mx-auto text-center flex justify-center lg:mb-[100px] mb-[50px]"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <div className="lg:max-w-[60%] max-w-[80%]">
          <h1 className="font-bold text-[30px] lg:text-[50px]">Our Services</h1>
          <p className="font-Dmsans text-[16px] lg:text-[20px]">
            We Create digital marketing campaign that go beyond the screen and
            deliver real results, real-time, personalized adverts in the palm of
            your hand
          </p>
        </div>
      </motion.div>

      <div className="w-[91%] mx-auto">
        <div className="w-full flex flex-col gap-y-[20px]">
          <div className="w-full flex justify-center ">
            {/* Left Services */}
            <motion.div className="w-full px-[10px] lg:px-[60px] flex-col justify-around hidden md:flex">
              {services.slice(0, 3).map((service, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: isVisible ? 1 : 0,
                  }}
                  transition={{ duration: 0.8, delay: 1 }}
                  key={service.id}
                  className="border-l-[2px] border-[#FF335D] pl-[20px]"
                >
                  <motion.h1
                    variants={contentVariants}
                    initial="hidden"
                    whileInView="visible"
                    custom={index + 4}
                    viewport={{ once: true }}
                    className="text-[16px] xl:text-[20px] font-semibold text-[#FF335D]"
                  >
                    {service.title}
                  </motion.h1>
                  <motion.p
                    variants={contentVariants}
                    initial="hidden"
                    whileInView="visible"
                    custom={index + 4}
                    viewport={{ once: true }}
                    className="text-[12px] xl:text-[16px] font-Dmsans text-[#343434]"
                  >
                    {service.description}
                  </motion.p>
                </motion.div>
              ))}
            </motion.div>

            {/* Video Section */}
            <div className="w-[280px] md:min-w-[300px] 2xl:min-w-[400px]">
              <motion.div
                className="w-full flex items-center justify-center relative cursor-pointer"
                variants={videoContainerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onClick={handleVideoPlayPause}
              >
                <div className="flex items-center h-[98%] bg-black w-[93%] justify-center overflow-hidden absolute rounded-[25px] md:rounded-[40px] 2xl:rounded-[60px]">
                  <div className="relative overflow-hidden w-[280px] md:w-[300px] 2xl:w-[400px] bg-slate-500">
                    <video
                      ref={videoRef}
                      className="w-full h-full object-contain"
                      autoPlay={false}
                      loop
                      playsInline
                      onPlay={() => setIsVideoPlaying(true)}
                      onPause={() => setIsVideoPlaying(false)}
                    >
                      <source
                        src="./landing/services/serv.mp4"
                        type="video/mp4"
                      />
                    </video>

                    {/* Play/Pause Button Overlay */}
                    <motion.div
                      className="absolute inset-0 flex items-center justify-center z-[40] "
                      variants={playButtonVariants}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                    >
                      <motion.button
                        className={`${
                          isVideoPlaying ? "opacity-0" : ""
                        } w-16 h-16 bg-white/80 rounded-full flex items-center justify-center`}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        {isVideoPlaying ? (
                          <Pause className="w-8 h-8 text-[#FF335D]" />
                        ) : (
                          <Play className="w-8 h-8 text-[#FF335D] ml-1" />
                        )}
                      </motion.button>
                    </motion.div>
                  </div>
                </div>

                <img
                  className="object-contain w-full z-[30]"
                  src="./landing/services/phoneV.png"
                  alt=""
                />
              </motion.div>
            </div>

            {/* Right Services */}
            <motion.div className="w-full px-[10px] lg:px-[60px] flex-col justify-around hidden md:flex">
              {services.slice(3, 6).map((service, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: isVisible ? 1 : 0,
                  }}
                  transition={{ duration: 0.8, delay: 1 }}
                  key={service.id}
                  className="border-l-[2px] border-[#FF335D] pl-[20px]"
                >
                  <motion.h1
                    variants={contentVariants}
                    initial="hidden"
                    whileInView="visible"
                    custom={index + 4}
                    viewport={{ once: true }}
                    className="text-[16px] xl:text-[20px] font-semibold text-[#FF335D]"
                  >
                    {service.title}
                  </motion.h1>
                  <motion.p
                    variants={contentVariants}
                    initial="hidden"
                    whileInView="visible"
                    custom={index + 4}
                    viewport={{ once: true }}
                    className="text-[12px] xl:text-[16px] font-Dmsans text-[#343434]"
                  >
                    {service.description}
                  </motion.p>
                </motion.div>
              ))}
            </motion.div>
          </div>

          {/* Bottom Services - Desktop */}
          {/* <motion.div className="w-full hidden lg:flex p-[20px] justify-around">
            {services.slice(4).map((service, index) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: isVisible ? 1 : 0,
                }}
                transition={{ duration: 0.8, delay: 1 }}
                key={service.id}
                className="border-l-[2px] border-[#FF335D] pl-[20px] max-w-[25%]"
              >
                <motion.h1
                  className="text-[16px] xl:text-[20px] font-semibold text-[#FF335D]"
                  variants={contentVariants}
                  initial="hidden"
                  whileInView="visible"
                  custom={index + 4}
                  viewport={{ once: true }}
                >
                  {service.title}
                </motion.h1>
                <motion.p
                  variants={contentVariants}
                  initial="hidden"
                  whileInView="visible"
                  custom={index + 4}
                  viewport={{ once: true }}
                  className="text-[12px] xl:text-[16px] font-Dmsans text-[#343434]"
                >
                  {service.description}
                </motion.p>
              </motion.div>
            ))}
          </motion.div> */}

          {/* Mobile Services */}
          <div className="w-full md:hidden flex p-[20px] flex-wrap justify-center gap-[30px]">
            {services.map((service, index) => (
              <motion.div
                key={service.id}
                className={` ${index % 2 === 0 ? "border-r-[2px]" : "border-l-[2px]"}  border-[#FF335D] text-center py-[20px] px-[20px] min-w-[80%] :min-w-[200px] max-w-[30%]`}
                variants={contentVariants}
                initial="hidden"
                whileInView="visible"
                custom={index}
                viewport={{ once: true }}
              >
                <h1 className="text-[16px] xl:text-[20px] font-semibold text-[#FF335D]">
                  {service.title}
                </h1>
                <p className="text-[12px] xl:text-[16px] font-Dmsans text-[#343434]">
                  {service.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
