import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import HeroSection from './components/Hero';
import Portfolio from './components/Portfolio';
import ServicesSection from './components/Services';
import Team from './components/Team';
import Testimony from './components/Testimony';


const Landingv3 = () => {
  return (
    <div className="w-full h-screen relative">
    <img className="scale-[3] z-[0] fixed" src="/landingv3/gradient.svg" alt="" />
    <Header />
    <div className="h-[calc(100vh-12%)] relative mt-[12%]">
      <div className="absolute w-full h-full transition-opacity duration-300">
        <div id="home">
          <HeroSection/>
        </div>
        <div id="services" className="">
          <ServicesSection/>
        </div>
        <div id="temoignages" className="">
          <Testimony/>
        </div>
        <div id="realisations" className="">
          <Portfolio/>
        </div>
        <div id="blogs" className="">
          <Blogs/>
        </div>
        <div id="team" className="">
          <Team/>
        </div>
        <div id="contact">
          <Contact/>
        </div>
        <Footer/>
      </div>
    </div>
  </div>
  );
};

export default Landingv3;